<template>
  <b-nav vertical>
    <img
      alt="Tecnomodel Logo"
      src="../assets/tecnomodel_white_logo.png"
      class="logo"
    />
    <b-button
      variant="outline-light"
      class="mb-2"
      to="embed-page?url=https://admin.tecnomodel-treni.it/supply_management.php"
      >Gestione classica</b-button
    >
    <span v-for="(item, index) in menu" :key="index">
      <sidebar-element
        :id="index.toString()"
        :name="item.name"
        :fa-icon="item.faIcon"
        :children="item.children"
      />
    </span>
  </b-nav>
</template>

<script>
import menu from "@/menu.json";
import SidebarElement from "./SidebarElement";

export default {
  name: "Sidebar",
  components: { SidebarElement },
  data() {
    return {
      menu: []
    };
  },
  mounted() {
    this.menu = menu;
  }
};
</script>

<style>
.text-white.nav-item.nav-item a {
  color: white;
}

.logo {
  width: 180px;
  padding-bottom: 20px;
}
</style>
