<template>
  <span>
    <b-nav-item disabled class="font-weight-bold text-white" v-b-toggle="id"
      ><font-awesome-icon v-if="faIcon" :icon="faIcon" />
      {{ name }}
      <font-awesome-icon
        class="fa-pull-right small"
        :class="{
          'rotate-90-cw': downArrow,
          'rotate-90-cw-reverse': !downArrow && init
        }"
        icon="chevron-right"
      />
    </b-nav-item>
    <b-collapse :id="id" class="mt-2">
      <b-nav vertical class="p-2 bg-light rounded">
        <span v-for="children in children" :key="children.name">
          <b-nav-item
            v-if="children.href"
            :to="{ name: 'EmbedPage', query: { url: children.href } }"
            class="text-main-color"
            ><font-awesome-icon
              v-if="children.faIcon"
              :icon="children.faIcon"
            />
            {{ children.name }}</b-nav-item
          >
          <b-nav-item
            v-if="children.to"
            :to="children.to"
            class="text-main-color"
            ><font-awesome-icon
              v-if="children.faIcon"
              :icon="children.faIcon"
            />
            {{ children.name }}</b-nav-item
          >
        </span>
      </b-nav>
    </b-collapse>
  </span>
</template>

<script>
export default {
  name: "SidebarElement",
  data() {
    return {
      downArrow: false,
      init: false
    };
  },
  props: {
    id: String,
    name: String,
    faIcon: {
      type: [String, Array],
      default: null
    },
    children: Array
  },
  mounted() {
    this.$root.$on("bv::collapse::state", (collapseId, isJustShown) => {
      if (collapseId === this.id) {
        this.downArrow = isJustShown;
        if (isJustShown) this.init = true;
      }
    });
  }
};
</script>

<style>
.rotate-90-cw {
  -webkit-animation: rotate-90-cw 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: rotate-90-cw 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes rotate-90-cw {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

@keyframes rotate-90-cw {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

.rotate-90-cw-reverse {
  -webkit-animation: rotate-90-cw-reverse 0.4s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: rotate-90-cw-reverse 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes rotate-90-cw-reverse {
  0% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}

@keyframes rotate-90-cw-reverse {
  0% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}
</style>
