import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentJWT: null,
    pageScrolled: false,
    scrollOffset: 0,
    header: true,
    workStationNumber: null,
    products: [],
    key: ""
  },
  mutations: {
    SET_JWT: function(state, jwt) {
      state.currentJWT = jwt;
      localStorage.setItem("token", jwt);
      axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
    },
    LOGOUT: function() {
      localStorage.removeItem("token");
      location.reload();
    },
    SET_PAGE_SCROLLED: function(state, value) {
      state.pageScrolled = value;
    },
    SET_OFFSET: function(state, offset) {
      state.scrollOffset = offset;
    },
    SET_HEADER: function(state, value) {
      state.header = value;
    },
    setCategoryProducts(state, value) {
      state.products.push(...value);
    },
    setCategoryKey(state, value) {
      state.key = value;
    },
    clearCategoryProducts(state) {
      state.products = [];
    }
  },
  actions: {
    logout({ commit }) {
      commit("LOGOUT");
    }
  },
  getters: {
    jwt: function(state) {
      return state.currentJWT;
    },
    jwtData: function(state, getters) {
      return state.currentJWT
        ? JSON.parse(atob(getters.jwt.split(".")[1]))
        : null;
    },
    jwtSubject: function(state, getters) {
      return getters.jwtData ? getters.jwtData.sub : null;
    },
    jwtIssuer: function(state, getters) {
      return getters.jwtData ? getters.jwtData.iss : null;
    },
    jwtPayload: function(state, getters) {
      return getters.jwtData ? getters.jwtData.data : null;
    },
    hasPageScrolled: function(state) {
      return state.pageScrolled;
    },
    hasPageNotScrolled: function(state) {
      return state.pageScrolled === false;
    },
    getOffset: function(state) {
      return state.scrollOffset;
    },
    getHeader: function(state) {
      return state.header;
    },
    getWorkStationNumber: function(state) {
      return state.workStationNumber;
    },
    getCategoryProducts(state) {
      return state.products;
    }
  },
  modules: {}
});
