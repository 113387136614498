import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./plugins/bootstrap-vue";
import axios from "axios";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faExclamationTriangle,
  faCalendarCheck,
  faHeart,
  faHome,
  faShoppingCart,
  faSearch,
  faArrowUp,
  faAngleRight,
  faTimes,
  faSignInAlt,
  faChevronDown,
  faChevronRight,
  faTrash,
  faInbox,
  faUser,
  faPhone,
  faStopwatch,
  faClipboardCheck,
  faMinus,
  faPlus,
  faAngleLeft,
  faSync,
  faArrowRight,
  faTh,
  faList,
  faPrint,
  faBarcode,
  faBox,
  faCopy,
  faTruck,
  faPause,
  faDownload,
  faArrowDown,
  faUpload,
  faCheckCircle,
  faTimesCircle,
  faPen,
  faWarehouse,
  faFileExcel,
  faChartBar,
  faChartLine,
  faBars,
  faLongArrowAltUp,
  faLongArrowAltDown,
  faLock,
  faEnvelope
} from "@fortawesome/free-solid-svg-icons";
import { faHeart as farHeart } from "@fortawesome/free-regular-svg-icons";
import { faWhatsapp, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import i18n from "./i18n";

library.add(faExclamationTriangle);
library.add(faCalendarCheck);
library.add(faHeart);
library.add(farHeart);
library.add(faHome);
library.add(faShoppingCart);
library.add(faSearch);
library.add(faArrowUp);
library.add(faAngleRight);
library.add(faTimes);
library.add(faSignInAlt);
library.add(faChevronDown);
library.add(faChevronRight);
library.add(faTrash);
library.add(faInbox);
library.add(faUser);
library.add(faPhone);
library.add(faStopwatch);
library.add(faWhatsapp);
library.add(faClipboardCheck);
library.add(faMinus);
library.add(faPlus);
library.add(faAngleLeft);
library.add(faSync);
library.add(faArrowRight);
library.add(faTh);
library.add(faList);
library.add(faPrint);
library.add(faBarcode);
library.add(faBox);
library.add(faCopy);
library.add(faTruck);
library.add(faPause);
library.add(faDownload);
library.add(faArrowDown);
library.add(faUpload);
library.add(faCheckCircle);
library.add(faTimesCircle);
library.add(faPen);
library.add(faWarehouse);
library.add(faFileExcel);
library.add(faChartBar);
library.add(faChartLine);
library.add(faBars);
library.add(faLongArrowAltUp);
library.add(faLongArrowAltDown);
library.add(faLock);
library.add(faYoutube);
library.add(faEnvelope);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.prototype.$http = axios;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  created() {
    const token = localStorage.getItem("token");

    if (token) {
      this.$store.commit("SET_JWT", token);
    }

    axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response.status === 401) {
          this.$store.dispatch("logout");
        }

        return Promise.reject(error);
      }
    );
  },

  i18n,
  render: h => h(App)
}).$mount("#app");
