<template>
  <b-navbar toggleable="lg" type="dark" class="bg-gradient-to-right-primary">
    <b-button class="d-md-none" v-b-toggle.sidebar-1 variant="primary"
      ><font-awesome-icon icon="bars"
    /></b-button>
    <b-navbar-brand href="#"></b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown right>
          <template #button-content>
            <em>{{ $store.getters.jwtPayload.email }}</em>
          </template>
          <b-dropdown-item @click="logout">Log Out</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
    <b-navbar-nav>
      <b-nav-item :to="{ name: 'WorkStationConfiguration' }"
        ><b-badge variant="light" class="text-dark">{{
          workStationNumber
        }}</b-badge></b-nav-item
      >
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
export default {
  name: "Navbar",
  computed: {
    workStationNumber: function() {
      if (!this.$store.getters.getWorkStationNumber) return "?";
      return this.$store.getters.getWorkStationNumber;
    }
  },
  mounted() {
    this.$store.state.workStationNumber = localStorage.getItem(
      "workStationNumber"
    );
  },
  methods: {
    logout: function() {
      this.$store.dispatch("logout");
    }
  }
};
</script>

<style>
.bg-gradient-to-right-primary {
  background-color: #4e73df;
  background-image: linear-gradient(to right, #4e73df 10%, #224abe 100%);
  background-size: cover;
}
</style>
